.contacts-page-body {
    height: calc(100vh - 7vw) !important;
    overflow-y: scroll;
    background-color: #ffffff;
    box-sizing: border-box;
}

.contacts-image-container {
    height: 35vw;
}

.contacts-shutterstock-image {
    height: 102%;
    width: 100%;
}

.contacts-separator-line-image {
    border: 0.1vw solid #00d1ff;
    background-color: #00d1ff;
    position: relative;
    top: -23vw;
    width: 10vw;
}

.contacts-image-caption {
    color: #ffffff;
    text-align: center;
    position: relative;
    top: -23vw;
    font-family: "Oswald";
    font-size: 4vw;
    line-height: 5vw;
    font-weight: 500;
}

.contacts-page-container {
    background-color: #ffffff;
    color: #000000;
    display: block;
    padding-top: 5%;
    text-align: center;
}

.contacts-title {
    padding-bottom: 3%;
    font-family: "Open Sans";
    font-size: 1.1vw;
    font-weight: 700;
}

.contacts-subtitle-one {
    font-weight: 400;
}

.contacts-subtitle-two {
    font-weight: 400;
    color: #3b83d6;
}

.contacts-subtitle-three {
    font-weight: 400;
}

.contacts-mail-mark {
    color: #000000;
}

.contact-report-form {
    width: 35%;
    height: 70%;
    margin: 5% auto;
    background-color: #00d1ff;
    border-radius: 1vh;
    box-shadow: rgba(60, 66, 87, 0.12) 0px 7px 14px 0px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px;
}

.contact-report-form-header {
    width: 85%;
    display: inline-flex;
    padding: 3% 8% 3% 8%;
    align-items: center;
    justify-content: space-between;
    font-family: "Open Sans";
    font-size: 1.1vw;
    font-weight: 700;
    color: #000000;
}

.contact-report-form-contact {
    font-size: calc(1em + 1px);
}

.contact-report-form-logo {
    display: inline-flex;
    width: 20%;
    height: auto;
}

.contact-report-form-input {
    display: block !important;
    margin: 3% auto 3% auto !important;
    width: 85% !important;
    height: 2.5vw;
    background: #ffffff !important;
    color: #000000 !important;
    border: 1px solid #00d1ff;
    border-radius: 1vh;
    box-sizing: border-box;
    font-family: "Open Sans";
    font-size: 1.1vw;
    font-weight: 700;
    padding: 1.5vh;
    &::placeholder {
        font-family: "Open Sans";
        font-size: 1.1vw;
        line-height: 2.1vw;
        font-weight: 500;
        z-index: 999;
        padding: 1.5vh;
    }
}

.contact-report-form-message {
    display: block !important;
    margin: 3% auto 3% auto !important;
    padding-bottom: 23% !important;
    width: 85% !important;
    background: #ffffff !important;
    color: #000000 !important;
    border: 1px solid #00d1ff;
    border-radius: 1vh;
    box-sizing: border-box;
    font-family: "Open Sans";
    font-size: 1.1vw;
    font-weight: 500;
    padding: 1.5vh;
    &::placeholder {
        font-family: "Open Sans";
        font-size: 1.1vw;
        line-height: 2.1vw;
        font-weight: 500;
        z-index: 999;
        padding: 1.5vh;
    }
}

.warning-message {
    padding-left: 55px;
    color: var(--status-red) !important;
}

.contact-report-form-submitted {
    padding: 3.5% 8% 0% 0%;
    // margin-left: 10vw;
    font-weight: bold;
    color: white;
}

.contact-report-form-footer {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.contact-report-form-button {
    width: 10vw;
    height: 3vw;
    margin: 2% 0% 5% 8%;
    background-color: transparent;
    background-repeat: no-repeat;
    color: white;
    z-index: 999;
    text-align: center;
    border: 1.5px solid white;
    box-sizing: border-box;
    border-radius: 5px;
    cursor: pointer;
    overflow: hidden;
    text-align: center;
    font-family: "Open Sans";
    font-size: 1.1vw;
    font-weight: 700;
    z-index: 999;
}

input:focus{
    outline: none;
}

@media only screen and (min-width: 280px) and (max-width: 599px) {
    .contacts-page-body {
        height: calc(100vh - 31vw) !important;
        max-width: 100vw !important;
        max-width: 100%;
        overflow-x: hidden;
        overflow-y: scroll;
        background-color: #ffffff;
        padding: 0px;
    }

    .contacts-image-container {
        width: 100%;
        height: 100%;
    }

    .contacts-shutterstock-image {
        width: 100%;
        height: 95%;
    }

    .contacts-separator-line-image {
        top: -50vh;
        width: 20vw;
    }

    .contacts-image-caption {
        top: -50vh;
        font-size: 10vw;
        line-height: 12vw;
    }

    .contacts-page-container {
        padding: 0% 10% 10% 10%;
    }

    .contact-report-form {
        width: 100%;
        height: 100%;
    }

    .contacts-title {
        font-size: calc(0.9em + 1vmin);
        margin-top: 20px;
    }

    .contacts-subtitle-one .contacts-subtitle-two .contacts-subtitle-three {
        font-size: calc(0.8em + 1vmin);
    }

    .contact-report-form-container {
        padding: 5%;
        border-radius: 5px;
    }

    .contact-report-form-contact {
        font-size: calc(4.5em + 1vmin);
    }

    .contact-report-form-logo {
        width: 10vh;
    }

    .contact-report-form-input {
        height: 4vh;
        border-radius: 1vh;
        &::placeholder {
            font-size: calc(1.6em + 1vmin);
            line-height: 4vh;
        }
    }

    input.contact-report-form-message {
        border-radius: 1vh;
        padding-top: 1.4vh;
        &::placeholder {
            padding-top: 7% !important;
            font-size: calc(1.6em + 1vmin);
            line-height: 4vh;
        }
    }

    .contact-report-form-button {
        width: 14vh;
        height: 5vh;
        font-size: calc(0.8em + 1vmin);
    }
}

@media only screen and (min-width: 600px) and (max-width: 960px) {
    .contacts-page-body {
        height: calc(100vh - 24vw) !important;
        max-width: 100vw !important;
        max-width: 100%;
        overflow-x: hidden;
        overflow-y: scroll;
        background-color: #ffffff;
        padding: 0px;
    }

    .contacts-image-container {
        width: 100%;
        height: 100%;
    }

    .contacts-shutterstock-image {
        width: 100%;
        height: 95%;
    }

    .contacts-separator-line-image {
        top: -50vh;
        width: 20vw;
    }

    .contacts-image-caption {
        top: -50vh;
        font-size: 10vw;
        line-height: 12vw;
    }

    .contacts-page-container {
        padding: 0 5% 0 5%;
    }

    .contact-report-form {
        width: 100%;
        height: 60%;
    }

    .contacts-title {
        font-size: calc(1.8em + 1vmin);
    }

    .contact-report-form-container {
        padding: 5%;
        border-radius: 5px;
    }

    .contact-report-form-contact {
        font-size: calc(4.9em + 1vmin);
    }

    .contact-report-form-logo {
        width: 10vh;
    }

    .contact-report-form-input {
        height: 6vh;
        border-radius: 1vh;
        &::placeholder {
            font-size: calc(1.2em + 1vmin);
            line-height: 4vh;
        }
    }

    input.contact-report-form-message {
        border-radius: 1vh;
        padding-top: 1.4vh;
        &::placeholder {
            padding-top: 3% !important;
            font-size: calc(1.1em + 1vmin);
            line-height: 4vh;
        }
    }

    .contact-report-form-button {
        width: 18vh;
        height: 5vh;
        font-size: calc(1.1em + 1vmin);
    }
}

@media only screen and (min-height: 250px) and (max-height: 550px),
    only screen and (min-device-height: 250px) and (max-device-height: 550px) and (orientation: landscape) {
    .contacts-page-body {
        height: calc(100vh - 11vw) !important;
        width: 100vw !important;
        max-width: 100%;
        overflow-x: hidden;
        overflow-y: scroll;
        background-color: #ffffff;
        padding: 0px;
    }

    .contacts-image-container {
        width: 100%;
        height: 100%;
    }

    .contacts-shutterstock-image {
        width: 100%;
        height: 95%;
    }

    .contacts-separator-line-image {
        top: -50vh;
        width: 20vw;
    }

    .contacts-image-caption {
        top: -60vh;
        font-size: calc(3.5em + 1vmin);
        line-height: calc(3.6em + 1vmin);
    }

    .contacts-page-container {
        padding: 0 5% 0 5%;
    }

    .contact-report-form {
        width: 85%;
        height: 60%;
    }

    .contacts-title {
        font-size: calc(0.9em + 1vmin);
        line-height: calc(1.1em + 1vmin);
    }

    .contacts-subtitle-one .contacts-subtitle-two .contacts-subtitle-three {
        font-size: calc(0.8em + 1vmin);
    }

    .contact-report-form-container {
        padding: 5%;
        border-radius: 5px;
    }

    .contact-report-form-contact {
        font-size: calc(1.7em + 1vmin);
    }

    .contact-report-form-logo {
        width: calc(8em + 1vmin);
    }

    .contact-report-form-input {
        height: calc(3.5em + 1vmin);
        border-radius: 1vh;
        &::placeholder {
            font-size: calc(1.2em + 1vmin);
            line-height: calc(1.2em + 1vmin);
        }
    }

    input.contact-report-form-message {
        border-radius: 1vh;
        padding-top: 1.4vh;
        &::placeholder {
            padding-top: 3% !important;
            font-size: calc(1.2em + 1vmin);
            line-height: 4vh;
        }
    }

    .contact-report-form-button {
        width: calc(4.5em + 1vmin);
        height: calc(1.6em + 1vmin);
        font-size: calc(1em + 1vmin);
        margin-left: 14vh;
    }
}

.about-page-body {
    height: calc(100vh - 7vw) !important;
    overflow-y: scroll;
    background-color: #ffffff;
}

.about-image-container {
    height: 35vw;
}

.about-shutterstock-image {
    height: 106%;
    width: 100%
}

.about-separator-line-image {
    border: 0.1vw solid #00d1ff;
    background-color: #00d1ff;
    position: relative;
    top: -24vw;
    width: 10vw;
}

.about-image-caption {
    color: #ffffff;
    text-align: center;
    position: relative;
    top: -24vw;
    font-family: "Oswald";
    font-size: 4vw;
    line-height: 5vw;
    font-weight: 500;
}

.about-page-container {
    background-color: #ffffff;
    color: #000000;
}

.about-container-separator-line-one {
    border: 0.1vw solid #00d1ff;
    background-color: #00d1ff;
    position: relative;
    top: 7vw;
    width: 10vw;
}

.digital-transformation-title {
    padding: 8% 0% 0% 0%;
    color: #28348a;
    text-align: center;
    font-family: "Open Sans";
    font-size: 2vw;
    line-height: 2.2vw;
    font-weight: 700;
    overflow: visible;
    z-index: 9999;
}

.digital-transformation-description {
    width: 45vw;
    padding: 3% 0% 5% 0%;
    color: #000000;
    margin: 1% auto;
    text-align: center;
    font-family: "Open Sans";
    font-size: 1.1vw;
    line-height: 1.7vw;
    font-weight: 700;
}

.about-container-separator-line-two {
    border: 0.1vw solid #00d1ff;
    background-color: #00d1ff;
    position: relative;
    width: 10vw;
}

.implementation-reasons-title {
    padding: 1% 10% 0% 10%;
    color: #28348a;
    text-align: center;
    font-family: "Open Sans";
    font-size: 2vw;
    line-height: 2.2vw;
    font-weight: 700;
    overflow: visible;
    z-index: 9999;
}

.implementation-reasons-blocks {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 4% 10% 10% 10%;
}

.implementation-reasons-block {
    width: 25vw;
    height: 20vw;
    margin: 0% 1% 2% 0%;
    padding: 0% 5% 0% 5%;
    background-color: #f5f5f5;
    text-align: center;
}

.implementation-reasons-block-image-one {
    width: 4.6vw;
    padding-top: 10%;
}

.implementation-reasons-block-image-two {
    width: 4.6vw;
    padding-top: 10%;
}

.implementation-reasons-block-image-three {
    width: 4.6vw;
    padding-top: 10%;
}

.implementation-reasons-block-image-four {
    width: 4.6vw;
    padding-top: 10%;
}

.implementation-reasons-block-image-five {
    width: 4.6vw;
    padding-top: 10%;
}

.implementation-reasons-block-image-six {
    width: 4.6vw;
    padding-top: 10%;
}

.implementation-reasons-block-image-seven {
    width: 4.6vw;
    padding-top: 10%;
}

.implementation-reasons-block-text {
    text-align: center;
    font-family: "Open Sans";
    font-size: 1.1vw;
    line-height: 1.2vw;
    padding-top: 15%;
}

.about-container-separator-line-three {
    border: 0.1vw solid #00d1ff;
    background-color: #00d1ff;
    position: relative;
    width: 10vw;
}

.project-consortium-title {
    padding: 1% 0% 0% 0%;
    color: #28348a;
    text-align: center;
    font-family: "Open Sans";
    font-size: 2vw;
    line-height: 2.2vw;
    font-weight: 700;
    overflow: visible;
    z-index: 9999;
}

.project-consortium-content {
    width: 45vw;
    padding: 3% 0% 5% 0%;
    color: #000000;
    margin: 1% auto;
    text-align: center;
    font-family: "Open Sans";
    font-size: 1.1vw;
    line-height: 2vw;
    font-weight: 700;
}

.partners-logo {
    display: inline-flex;
    justify-content: space-around;
    padding-bottom: 8%;
    width: 100%;
    height: auto;
    background-color: #ffffff;
    align-items: center;
}

.partners-logo-ericsson {
    width: 12%;
}

.partners-logo-gauss {
    width: 12%;
}

.partners-logo-hzjz {
    width: 12%;
}

.partners-logo-telemetris {
    width: 12%;
}

.goals-expected-result-title {
    padding: 1% 0% 0% 0%;
    color: #28348a;
    text-align: center;
    font-family: "Open Sans";
    font-size: 2vw;
    line-height: 2.2vw;
    font-weight: 700;
    overflow: visible;
    z-index: 9999;
}

.goals-expected-result-content {
    width: 45vw;
    padding: 3% 0% 5% 0%;
    color: #000000;
    margin: 1% auto;
    text-align: center;
    font-family: "Open Sans";
    font-size: 1.1vw;
    line-height: 2vw;
    font-weight: 700;
}

.links-title {
    padding: 1% 0% 0% 0%;
    color: #28348a;
    text-align: center;
    font-family: "Open Sans";
    font-size: 2vw;
    line-height: 2.2vw;
    font-weight: 700;
    overflow: visible;
    z-index: 9999;
}

.links-content {
    width: 45vw;
    padding: 3% 0% 5% 0%;
    margin: 1% auto;
    color: #3b83d6;
    text-align: center;
    font-family: "Open Sans";
    font-size: 1.1vw;
    line-height: 2vw;
    font-weight: 400;
}

.links-description {
    padding-top: 3%;
}

.about-finances-container {
    display: flex;
    padding-top: 2.5%;
    height: 8vw;
    background-color: #1b4874;
    text-align: center;
    font-family: "Open Sans";
    font-size: 1.1vw;
    line-height: 2vw;
}

.about-finances-block {
    width: 33%;
}

.about-finances-text {
    color: #ffffff;
    justify-content: space-between;
    font-weight: 400;
}

.about-finances-numbers {
    justify-content: space-between;
    color: #00d1ff;
    font-weight: 700;
}

@media only screen and (min-width: 280px) and (max-width: 599px) {
    .about-page-body {
        height: calc(100vh - 30vw) !important;
        width: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
        background-color: #ffffff;
        padding: 0px;
    }

    .about-image-container {
        width: 100%;
        height: 100%;
    }

    .about-shutterstock-image {
        width: 100%;
        height: 95%;
    }

    .about-separator-line-image {
        width: 20vw;
        top: -55vh;
    }

    .about-image-caption {
        top: -55vh;
        font-size: 10vw;
        line-height: 12vw;
    }

    .about-container-separator-line-one {
        width: 20vw;
    }

    .digital-transformation-title {
        font-size: 4vh;
        line-height: 5vh;
    }

    .digital-transformation-description {
        width: 90vw;
        padding: 3% 5% 5% 5%;
        font-size: 2vh;
        line-height: 2.5vh;
    }

    .about-container-separator-line-two {
        width: 20vw;
    }

    .implementation-reasons-title {
        font-size: 4vh;
        line-height: 5vh;
    }

    .implementation-reasons-blocks {
        padding: 5% 0 0 0;
    }

    .implementation-reasons-block {
        width: 35vw;
        height: 70vw;
        margin: 1% auto;
    }

    .implementation-reasons-block-image-one {
         width: 10vw;
    }

    .implementation-reasons-block-image-two {
         width: 10vw;
    }

    .implementation-reasons-block-image-three {
         width: 10vw;
    }

    .implementation-reasons-block-image-four {
         width: 10vw;
    }

    .implementation-reasons-block-image-five {
         width: 10vw;
    }

    .implementation-reasons-block-image-six {
         width: 10vw;
    }

    .implementation-reasons-block-image-seven {
         width: 10vw;
    }

    .implementation-reasons-block-text {
        font-size: 3vw;
        line-height: 4.5vw;
    }

    .about-container-separator-line-three {
        width: 20vw;
    }

    .project-consortium-title {
        font-size: 4vh;
        line-height: 5vh;
    }

    .project-consortium-content {
        width: 90vw;
        padding: 3% 5% 5% 5%;
        font-size: 2vh;
        line-height: 2.5vh;
    }

    .goals-expected-result-title {
        font-size: 4vh;
        line-height: 5vh;
    }

    .goals-expected-result-content {
        width: 90vw;
        padding: 3% 5% 5% 5%;
        font-size: 2vh;
        line-height: 2.5vh;
    }

    .links-title {
        font-size: 4vh;
        line-height: 5vh;
    }

    .links-description {
        margin: 1% auto;
        font-size: 2vh;
        line-height: 2.5vh;
    }

    .about-finances-container {
        display: block;
        height: 25vh;
        font-size: 2vh;
        line-height: 2.5vh;
    }

    .about-finances-block {
        padding-top: 2.5%;
        width: 100%;
    }
}

@media only screen and (min-width: 600px) and (max-width: 960px) {
    .about-page-body {
        height: calc(100vh - 24vh) !important;
        width: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
        background-color: #ffffff;
        padding: 0px;
    }

    .about-image-container {
        width: 100%;
        height: 100%;
    }

    .about-shutterstock-image {
        width: 100%;
        height: 95%;
    }

    .about-separator-line-image {
        width: 20vw;
        top: -55vh;
    }

    .about-image-caption {
        top: -55vh;
        font-size: 10vw;
        line-height: 12vw;
    }

    .about-container-separator-line-one {
        width: 20vw;
    }

    .digital-transformation-title {
        font-size: 4vh;
        line-height: 5vh;
    }

    .digital-transformation-description {
        width: 90vw;
        padding: 3% 5% 5% 5%;
        font-size: 2vh;
        line-height: 2.5vh;
    }

    .about-container-separator-line-two {
        width: 20vw;
    }

    .implementation-reasons-title {
        font-size: 4vh;
        line-height: 5vh;
    }

    .implementation-reasons-blocks {
        padding: 5% 0 0 0;
    }

    .implementation-reasons-block {
        width: 35vw;
        height: 55vw;
        margin: 1% auto;
    }

    .implementation-reasons-block-image-one {
        width: 10vw;
    }

    .implementation-reasons-block-image-two {
        width: 10vw;
    }

    .implementation-reasons-block-image-three {
         width: 10vw;
    }

    .implementation-reasons-block-image-four {
         width: 10vw;
    }

    .implementation-reasons-block-image-five {
         width: 10vw;
    }

    .implementation-reasons-block-image-six {
         width: 10vw;
    }

    .implementation-reasons-block-image-seven {
         width: 10vw;
    }

    .implementation-reasons-block-text {
        font-size: 2.5vw;
        line-height: 4vw;
    }

    .about-container-separator-line-three {
        width: 20vw;
    }

    .project-consortium-title {
        font-size: 4vh;
        line-height: 5vh;
    }

    .project-consortium-content {
        width: 90vw;
        padding: 3% 5% 5% 5%;
        font-size: 2vh;
        line-height: 2.5vh;
    }

    .goals-expected-result-title {
        font-size: 4vh;
        line-height: 5vh;
    }

    .goals-expected-result-content {
        width: 90vw;
        padding: 3% 5% 5% 5%;
        font-size: 2vh;
        line-height: 2.5vh;
    }

    .links-title {
        font-size: 4vh;
        line-height: 5vh;
    }

    .links-description {
        margin: 1% auto;
        font-size: 2vh;
        line-height: 2.5vh;
    }

    .about-finances-container {
        display: block;
        height: 25vh;
        font-size: 2vh;
        line-height: 2.5vh;
    }

    .about-finances-block {
        padding-top: 2.5%;
        width: 100%;
    }
}

@media only screen and (min-height: 250px) and (max-height: 550px),
    only screen and (min-device-height: 250px) and (max-device-height: 550px) and (orientation: landscape) {
    .about-page-body {
        height: calc(100vh - 9vw) !important;
        width: 100vw !important;
        max-width: 100%;
        overflow-x: hidden;
        overflow-y: scroll;
        background-color: #ffffff;
        padding: 0px;
    }

    .about-image-container {
        width: 100%;
        height: 100%;
    }

    .about-shutterstock-image {
        width: 100%;
        height: 95%;
    }

    .about-separator-line-image {
        width: 20vw;
        top: -55vh;
    }

    .about-image-caption {
        top: -65vh;
        font-size: calc(3.5em + 1vmin);
        line-height: calc(3.6em + 1vmin);
    }

    .about-container-separator-line-one {
        width: 20vw;
    }

    .digital-transformation-title {
        font-size: calc(1.8em + 1vmin);
        line-height: calc(1em + 1vmin);
    }

    .digital-transformation-description {
        width: 90vw;
        padding: 3% 5% 5% 5%;
        font-size: 1em;
        line-height: calc(1.2em + 1vmin);
    }

    .about-container-separator-line-two {
        width: 20vw;
    }

    .implementation-reasons-title {
        font-size: calc(1.8em + 1vmin);
        line-height: calc(1em + 1vmin);
    }

    .implementation-reasons-blocks {
        padding: 5% 0 0 0;
    }

    .implementation-reasons-block {
        width: 50vw;
        height: 50vw;
        margin: 1% auto;
    }

    .implementation-reasons-block-image-one {
        width: 8vw;
    }

    .implementation-reasons-block-image-two {
        width: 8vw;
    }

    .implementation-reasons-block-image-three {
        width: 8vw;
    }

    .implementation-reasons-block-image-four {
        width: 8vw;
    }

    .implementation-reasons-block-image-five {
        width: 8vw;
    }

    .implementation-reasons-block-image-six {
        width: 8vw;
    }

    .implementation-reasons-block-image-seven {
        width: 8vw;
    }

    .implementation-reasons-block-text {
        font-size: 1em;
        line-height: calc(1.2em + 1vmin);
    }

    .about-container-separator-line-three {
        width: 20vw;
    }

    .project-consortium-title {
        font-size: calc(1.8em + 1vmin);
        line-height: calc(1em + 1vmin);
    }

    .project-consortium-content {
        width: 90vw;
        padding: 3% 5% 5% 5%;
        font-size: 1em;
        line-height: calc(1.2em + 1vmin);
    }

    .goals-expected-result-title {
        font-size: calc(1.8em + 1vmin);
        line-height: calc(1em + 1vmin);
    }

    .goals-expected-result-content {
        width: 90vw;
        padding: 3% 5% 5% 5%;
        font-size: 1em;
        line-height: calc(1.2em + 1vmin);
    }

    .links-title {
        font-size: calc(1.8em + 1vmin);
        line-height: calc(1em + 1vmin);
    }

    .links-description {
        margin: 1% auto;
        font-size: 1.5em;
        line-height: calc(1.2em + 1vmin);
    }

    .about-finances-container {
        display: block;
        height: 60vh;
        font-size: 1em;
        line-height: calc(1em + 1vmin);
    }

    .about-finances-block {
        padding-top: 2.5%;
        width: 100%;
    }
}

.landing-page-body {
    height: calc(100vh - 7vw) !important;
    max-width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    background-color: #ffffff;
}

.image-container {
    height: 55.9vw;
    position: relative;
}

.waterq-iri-video {
    height: 100%;
    width: 100%;
    object-fit: fill;
}

.play-button {
    position: relative;
    cursor: pointer;
    top: -35vw;
    left: 45vw;
    width: 8vw;
    border-radius: 50%;
    z-index: 99999;
}

.play-button-hidden {
    visibility: hidden;
}

.droplet-icon {
    color: #ffffff;
    position: relative;
    top: -25vw;
    left: 48vw;
    width: 2vw;
}

.droplet-icon-hidden {
    visibility: hidden;
}

.separator-line-image {
    border: 0.1vw solid #00d1ff;
    background-color: #00d1ff;
    position: relative;
    top: -33vw;
    width: 10vw;
}

.separator-line-image-hidden {
    visibility: hidden;
}

.image-caption1 {
    color: #ffffff;
    text-align: center;
    position: relative;
    top: -33vw;
    font-family: "Oswald";
    font-size: 4.3vw;
    line-height: 5vw;
    font-weight: 500;
}

.image-caption1-hidden {
    visibility: hidden;
}

.image-caption2 {
    color: #ffffff;
    text-align: center;
    position: relative;
    top: -33vw;
    font-family: "Open Sans";
    font-size: 2vw;
    line-height: 2.2vw;
    font-weight: 700;
}

.image-caption2-hidden {
    visibility: hidden;
}

.landing-page-container {
    align-content: center;
    background-color: #ffffff;
    position: relative;
}

.separator-line-landing {
    border: 0.1vw solid #00d1ff;
    background-color: #00d1ff;
    position: relative;
    top: 5vw;
    width: 10vw;
}

.landing-page-description {
    width: 45vw;
    margin: 10% auto;
    color: #000000;
    text-align: center;
    font-family: "Open Sans";
    font-size: 1.1vw;
    line-height: 2vw;
    font-weight: 700;
}

.infographic-01-title {
    color: #28348a;
    text-align: center;
    font-family: "Open Sans";
    font-size: 2vw;
    line-height: 2.2vw;
    font-weight: 700;
    overflow: visible;
    z-index: 9999;
}

.infographic-01 {
    display: block;
    margin: 2% auto;
    width: 75vw;
}

.infographic-01-subtitle-one {
    display: inline-flex;
    width: 50%;
    position: relative;
    color: #081b2e;
    left: 20vw;
    top: -44vw;
    font-family: "Open Sans";
    font-size: 1.5vw;
    line-height: 2.1vw;
    font-weight: 700;
    z-index: 999;
}

.infographic-01-subtitle-two {
    display: inline-flex;
    width: 50%;
    position: relative;
    color: #081b2e;
    top: -44vw;
    font-family: "Open Sans";
    font-size: 1.5vw;
    line-height: 2.1vw;
    font-weight: 700;
    z-index: 999;
}

.infographic-01-subtitle-three {
    display: inline-flex;
    width: 50%;
    position: relative;
    color: #081b2e;
    left: 20vw;
    top: -32vw;
    font-family: "Open Sans";
    font-size: 1.5vw;
    line-height: 2.1vw;
    font-weight: 700;
    z-index: 999;
}

.infographic-01-subtitle-four {
    display: inline-flex;
    width: 50%;
    position: relative;
    color: #081b2e;
    top: -32vw;
    font-family: "Open Sans";
    font-size: 1.5vw;
    line-height: 2.1vw;
    font-weight: 700;
    z-index: 999;
}

.waterq-system-container {
    display: flex;
    padding: 0% 15% 0% 15%;
    justify-content: space-between;
    column-gap: 1.8vw;
}

.IoT-sensor {
    display: block;
    margin: 2% auto;
    width: 10vw;
    height: 10vw;
}

.laboratory-analysis {
    display: block;
    margin: 2% auto;
    width: 30vw;
    width: 30vw;
}

.Infographic-02 {
    display: block;
    margin: 2% auto;
    width: 10vw;
    height: 10vw;
}

.waterq-system-title {
    margin-top: -1%;
    padding: 0% 0% 4vw 0%;
    color: #28348a;
    text-align: center;
    font-family: "Open Sans";
    font-size: 2vw;
    line-height: 2.2vw;
    font-weight: 700;
    z-index: 9999;
}

.waterq-system-subtitles {
    display: flex;
    padding: 0% 15% 0% 15%;
    justify-content: space-between;
    color: #28348a;
    font-family: "Open Sans";
    font-size: 1.5vw;
    line-height: 2.1vw;
    font-weight: 700;
    z-index: 999;
}

.waterq-platform {
    padding: 0% 15% 0% 15%;
    margin-bottom: -20%;
}

.infographic-03 {
    position: relative;
    width: 75vw;
}

.waterq-platform-title {
    padding: 10% 0% 4vw 0%;
    color: #28348a;
    text-align: center;
    font-family: "Open Sans";
    font-size: 2vw;
    line-height: 2.2vw;
    font-weight: 700;
}

.waterq-platform-subtitle {
    position: relative;
    top: 8vw;
    text-align: center;
    padding: 0% 15% 0% 15%;
    color: #28348a;
    font-family: "Open Sans";
    font-size: 1.5vw;
    line-height: 2.1vw;
    font-weight: 700;
    z-index: 999;
}

.analysis {
    position: relative;
    top: -21vw;
    left: 17vw;
    width: 50vw;
}

.waterq-results-title {
    padding: 5% 0% 5% 0%;
    color: #28348a;
    text-align: center;
    font-family: "Open Sans";
    font-size: 2.1vw;
    line-height: 2.2vw;
    font-weight: 700;
}

.infographic-line {
    visibility: hidden;
}

.infographic-04 {
    width: 15vw;
}

.infographic-05 {
    width: 12vw;
}

.waterq-results-subtitles {
    display: flex;
    padding: 0% 25% 5% 33%;
    justify-content: space-between;
    color: #28348a;
    font-family: "Open Sans";
    font-size: 1.5vw;
    line-height: 2.1vw;
    font-weight: 700;
    z-index: 999;
}

.infographic-06 {
    position: relative;
    width: 75vw;
    left: 12.5vw;
    top: -5vw;
}

.waterq-mobile-aplication-container {
    display: flex;
    position: relative;
    top: -20%;
    padding: 0% 30% 0% 30%;
    justify-content: space-between;
}

.image-container-two {
    height: 100%;
}

.shutterstock-02 {
    width: 100%;
}

.integration-resolution {
    width: 70%;
    position: relative;
    color: #ffffff;
    top: 15vw;
    left: 15vw;
    text-align: center;
    font-family: "Oswald";
    font-size: 2.3vw;
    line-height: 3vw;
    font-weight: 500;
    z-index: 999;
}

.find-out-more {
    width: 20%;
    background-color: transparent;
    background-repeat: no-repeat;
    position: relative;
    color: #ffffff;
    top: 20vw;
    left: 40vw;
    z-index: 999;
    text-align: center;
    border: 0.2vw solid #ffffff;
    box-sizing: border-box;
    border-radius: 5px;
    cursor: pointer;
    overflow: hidden;
    text-align: center;
    font-family: "Oswald";
    font-size: 1.2vw;
    line-height: 3vw;
    font-weight: 500;
    z-index: 999;
}

@media only screen and (min-width: 280px) and (max-width: 599px) {
    .landing-page-body {
        height: calc(100vh - 31vw) !important;
        width: 100vw !important;
        overflow-y: scroll;
        background-color: #ffffff;
        padding: 0px;
    }

    .image-container {
        width: 100%;
        height: 100%;
    }

    .play-button {
        cursor: pointer;
        top: -38vh;
        left: 42vw;
        width: 16vw;
        border-radius: 50%;
        z-index: 1;
    }

    .separator-line-image {
        top: -60vh;
        width: 20vw;
    }

    .image-caption1 {
        top: -60vh;
        font-size: 3.8vh;
        line-height: 4.2vh;
    }
    .image-caption2 {
        top: -40vh;
        font-size: 1.6vh;
        line-height: 2vh;
    }

    .droplet-icon {
        visibility: hidden;
    }

    .separator-line-landing {
        width: 20vw;
    }

    .landing-page-description {
        width: 90%;
        margin: 10% auto;
        font-size: 2vh;
        line-height: 2.5vh;
    }

    .infographic-01-title {
        padding: 0 5% 0 5%;
        font-size: 4vh;
        line-height: 5vh;
    }

    .infographic-01 {
        padding-top: 15vh;
        height: 90vh;
    }

    .infographic-01-subtitle-one {
        position: relative;
        top: -80vh;
        left: 20vw;
        width: 60vw;
        font-size: 2vh;
        line-height: 2.5vh;
    }

    .infographic-01-subtitle-two {
        position: relative;
        top: -58vh;
        left: 25vw;
        width: 60vw;
        font-size: 2vh;
        line-height: 2.5vh;
    }

    .infographic-01-subtitle-three {
        position: relative;
        top: -34vh;
        left: 25vw;
        width: 60vw;
        font-size: 2vh;
        line-height: 2.5vh;
    }

    .infographic-01-subtitle-four {
        position: relative;
        top: -10vh;
        left: 20vw;
        width: 60vw;
        font-size: 2vh;
        line-height: 2.5vh;
    }

    .waterq-system-title {
        padding-bottom: 0;
        font-size: 4vh;
        line-height: 5vh;
    }

    .waterq-system-subtitles {
        display: block;
    }

    .waterq-system-subtitle-one {
        width: 100%;
        position: relative;
        top: 7vh;
        text-align: center;
        font-size: 2vh;
        line-height: 2.5vh;
    }

    .waterq-system-subtitle-two {
        width: 100%;
        position: relative;
        top: 32vh;
        text-align: center;
        font-size: 2vh;
        line-height: 2.5vh;
    }

    .waterq-system-subtitle-three {
        width: 100%;
        position: relative;
        top: 58vh;
        text-align: center;
        font-size: 2vh;
        line-height: 2.5vh;
    }

    .waterq-system-container {
        display: block;
    }

    .IoT-sensor {
        margin-top: 5vh;
        display: block;
        height: 20vh;
        width: 20vh;
    }

    .laboratory-analysis {
        margin-top: 5vh;
        display: block;
        height: 20vh;
        width: 30vh;
    }

    .Infographic-02 {
        margin-top: 10vh;
        display: block;
        height: 20vh;
        width: 20vh;
    }

    .waterq-platform-title {
        padding-top: 10vh;
        font-size: 4vh;
        line-height: 5vh;
    }

    .waterq-platform-subtitle {
        top: 3vh;
        font-size: 2vh;
        line-height: 2.5vh;
    }

    .waterq-results-title {
        position: relative;
        top: 23vh;
        font-size: 4vh;
        line-height: 5vh;
    }

    .waterq-results-subtitles {
        display: inline-flex;
        position: relative;
        top: 25vh;
        padding: 0 15% 0 15%;
        width: 70%;
        font-size: 2vh;
        line-height: 2.5vh;
    }

    .infographic-03 {
        display: flex;
        margin: 0 auto;
        left: 0px;
        top: -23vh;
    }

    .infographic-line {
        display: flex;
        visibility: visible;
        padding-top: 5vh;
        margin: 0 auto;
        width: 75vw;
    }

    .infographic-06 {
        display: flex;
        position: static;
        padding-top: 27vh;
        margin: 0 auto;
        width: 75vw;
    }

    .waterq-mobile-aplication-container {
        display: flex;
        position: relative;
        top: 20vh;
        padding: 0% 24vw 0% 17vw;
        justify-content: space-between;
    }

    .analysis {
        top: 5vw;
    }

    .waterq-results-subtitle-one {
        margin: 20px 55px 0px 5px;
    }

    .waterq-results-subtitle-two {
        width: 30vw;
        text-align: center;
    }

    .image-container-two {
        padding-top: -10vh;
    }

    .shutterstock-02 {
        height: 40vh;
    }

    .integration-resolution {
        width: 90vw;
        top: 34vh;
        left: 5vw;
        font-size: calc(1.2em + 1vmin);
        line-height: calc(1.3em + 1vmin);
    }

    .find-out-more {
        width: 40vw;
        top: 35vh;
        left: 30vw;
        border: 0.2vh solid #ffffff;
        border-radius: 5px;
        font-size: calc(0.5em + 1vmin);
        line-height: calc(2em + 1vmin);
    }
}

@media only screen and (min-width: 600px) and (max-width: 960px) {
    .landing-page-body {
        height: calc(100vh - 24vw) !important;
        width: 100vw !important;
        overflow-y: scroll;
        background-color: #ffffff;
        padding: 0px;  
    }

    .image-container {
        width: 100%;
        height: 100%;
    }

    .play-button {
        cursor: pointer;
        top: -38vh;
        left: 42vw;
        margin-top: 42px;
        width: 16vw;
        border-radius: 50%;
        z-index: 99999;
    }

    .separator-line-image {
        top: -60vh;
        width: 20vw;
    }

    .image-caption1 {
        top: -60vh;
        font-size: 3.8vh;
        line-height: 4.2vh;
    }
    .image-caption2 {
        top: -40vh;
        font-size: 1.6vh;
        line-height: 2vh;
    }

    .droplet-icon {
        visibility: hidden;
    }

    .separator-line-landing {
        width: 20vw;
    }

    .landing-page-description {
        width: 90%;
        margin: 10% auto;
        font-size: 2vh;
        line-height: 2.5vh;
    }

    .infographic-01-title {
        padding: 0 5% 0 5%;
        font-size: 4vh;
        line-height: 5vh;
    }

    .infographic-01 {
        padding-top: 15vh;
        height: 90vh;
    }

    .infographic-01-subtitle-one {
        position: relative;
        top: -80vh;
        left: 20vw;
        width: 60vw;
        font-size: 2vh;
        line-height: 2.5vh;
    }

    .infographic-01-subtitle-two {
        position: relative;
        top: -56vh;
        left: 25vw;
        width: 60vw;
        font-size: 2vh;
        line-height: 2.5vh;
    }

    .infographic-01-subtitle-three {
        position: relative;
        top: -33vh;
        left: 25vw;
        width: 60vw;
        font-size: 2vh;
        line-height: 2.5vh;
    }

    .infographic-01-subtitle-four {
        position: relative;
        top: -9vh;
        left: 20vw;
        width: 60vw;
        font-size: 2vh;
        line-height: 2.5vh;
    }

    .waterq-system-title {
        padding-bottom: 0;
        font-size: 4vh;
        line-height: 5vh;
    }

    .waterq-system-subtitles {
        display: block;
    }

    .waterq-system-subtitle-one {
        width: 100%;
        position: relative;
        top: 7vh;
        text-align: center;
        font-size: 2vh;
        line-height: 2.5vh;
    }

    .waterq-system-subtitle-two {
        width: 100%;
        position: relative;
        top: 32vh;
        text-align: center;
        font-size: 2vh;
        line-height: 2.5vh;
    }

    .waterq-system-subtitle-three {
        width: 100%;
        position: relative;
        top: 58vh;
        text-align: center;
        font-size: 2vh;
        line-height: 2.5vh;
    }

    .waterq-system-container {
        display: block;
    }

    .IoT-sensor {
        margin-top: 5vh;
        display: block;
        height: 20vh;
        width: 20vh;
    }

    .laboratory-analysis {
        margin-top: 4vh;
        display: block;
        height: 20vh;
        width: 30vh;
    }

    .Infographic-02 {
        margin-top: 10vh;
        display: block;
        height: 20vh;
        width: 20vh;
    }

    .waterq-platform-title {
        padding-top: 10vh;
        font-size: 4vh;
        line-height: 5vh;
    }

    .waterq-platform-subtitle {
        top: 3vh;
        font-size: 2vh;
        line-height: 2.5vh;
    }

    .waterq-results-title {
        position: relative;
        top: 23vh;
        font-size: 4vh;
        line-height: 5vh;
    }

    .waterq-results-subtitles {
        display: inline-flex;
        position: relative;
        top: 25vh;
        padding: 0 15% 0 20%;
        width: 65%;
        font-size: 2vh;
        line-height: 2.5vh;
    }

    .infographic-03 {
        display: flex;
        margin: 0 auto;
        left: 0px;
        top: -22vh;
    }

    .infographic-line {
        display: flex;
        visibility: visible;
        padding-top: 5vh;
        margin: 0 auto;
        width: 75vw;
    }

    .infographic-06 {
        display: flex;
        position: static;
        padding-top: 27vh;
        margin: 0 auto;
        width: 75vw;
    }

    .waterq-mobile-aplication-container {
        display: flex;
        position: relative;
        top: 20vh;
        padding: 0% 24vw 0% 17vw;
        justify-content: space-between;
    }

    .analysis {
        top: 5vw;
    }

    .waterq-results-subtitle-two {
        width: 30vw;
        text-align: center;
    }

    .image-container-two {
        padding-top: -30vh;
    }

    .shutterstock-02 {
        height: 40vh;
    }

    .integration-resolution {
        width: 90vw;
        top: 30vh;
        left: 5vw;
        font-size: calc(1.2em + 1vmin);
        line-height: calc(1.3em + 1vmin);
    }

    .find-out-more {
        width: 40vw;
        top: 35vh;
        left: 30vw;
        border: 0.2vh solid #ffffff;
        border-radius: 5px;
        font-size: calc(0.5em + 1vmin);
        line-height: calc(2em + 1vmin);
    }
}

@media only screen and (min-height: 250px) and (max-height: 550px),
    only screen and (min-device-height: 250px) and (max-device-height: 550px) and (orientation: landscape) {
    .landing-page-body {
        height: calc(100vh - 11vw) !important;
        width: 100vw !important;
        max-width: 100%;
        overflow-x: hidden;
        overflow-y: scroll;
        background-color: #ffffff;
        padding: 0px;
    }

    .image-container {
        width: 100%;
        height: 100%;
    }

    .play-button {
        cursor: pointer;
        top: -80vh;
        left: 42vw;
        width: 16vw;
        border-radius: 50%;
        z-index: 99999;
    }

    .separator-line-image {
        top: -80vh;
        width: 20vw;
    }

    .image-caption1 {
        top: -78vh;
        font-size: calc(1.2em + 1vmin);
        line-height: 4.2vh;
    }
    .image-caption2 {
        top: -70vh;
        font-size: calc(1em + 1vmin);
        line-height: 2vh;
    }

    .droplet-icon {
        visibility: hidden;
    }

    .separator-line-landing {
        width: 20vw;
    }

    .landing-page-description {
        width: 90%;
        margin: 10% auto;
        font-size: 1em;
        line-height: calc(1.2em + 1vmin);
    }

    .infographic-01-title {
        padding: 0 5% 0 5%;
        font-size: calc(1.8em + 1vmin);
        line-height: calc(1em + 1vmin);
    }

    .infographic-01 {
        padding-top: 15vh;
        height: 100% !important;
    }

    .infographic-01-subtitle-one {
        position: relative;
        top: -170vw;
        left: 20vw;
        width: 60vw;
        font-size: 1em;
        line-height: calc(1.2em + 1vmin);
    }

    .infographic-01-subtitle-two {
        position: relative;
        top: -135vw;
        left: 25vw;
        width: 60vw;
        font-size: 1em;
        line-height: calc(1.2em + 1vmin);
    }

    .infographic-01-subtitle-three {
        position: relative;
        top: -95vw;
        left: 25vw;
        width: 60vw;
        font-size: 1em;
        line-height: calc(1.2em + 1vmin);
    }

    .infographic-01-subtitle-four {
        position: relative;
        top: -55vw;
        left: 20vw;
        width: 60vw;
        font-size: 1em;
        line-height: calc(1.2em + 1vmin);
    }

    .waterq-system-title {
        padding-bottom: 0;
        font-size: calc(1.8em + 1vmin);
        line-height: calc(1em + 1vmin);
    }

    .waterq-system-subtitles {
        display: block;
    }

    .waterq-system-subtitle-one {
        width: 100%;
        position: relative;
        top: 10vh;
        text-align: center;
        font-size: 1em;
        line-height: calc(1.2em + 1vmin);
    }

    .waterq-system-subtitle-two {
        width: 100%;
        position: relative;
        top: 60vh;
        text-align: center;
        font-size: 1em;
        line-height: calc(1.2em + 1vmin);
    }

    .waterq-system-subtitle-three {
        width: 100%;
        position: relative;
        top: 98vh;
        text-align: center;
        font-size: 1em;
        line-height: calc(1.2em + 1vmin);
    }

    .waterq-system-container {
        display: block;
    }

    .IoT-sensor {
        margin-top: 5vh;
        display: block;
        height: 40vh;
        width: 40vh;
    }

    .laboratory-analysis {
        margin-top: 5vh;
        display: block;
        height: 40vh;
        width: 60vh;
    }

    .Infographic-02 {
        margin-top: 10vh;
        display: block;
        height: 40vh;
        width: 40vh;
    }

    .waterq-platform-title {
        padding-top: 35vh;
        font-size: calc(1.8em + 1vmin);
        line-height: calc(1em + 1vmin);
    }

    .waterq-platform-subtitle {
        top: -8vh;
        font-size: 1em;
        line-height: calc(1.2em + 1vmin);
    }

    .waterq-results-title {
        position: relative;
        top: 15vw;
        font-size: calc(1.8em + 1vmin);
        line-height: calc(1em + 1vmin);
    }

    .waterq-results-subtitles {
        display: inline-flex;
        position: relative;
        top: 25vh;
        padding: 0 15% 0 20%;
        width: 80%;
        font-size: 1em;
        line-height: calc(1.2em + 1vmin);
    }

    .infographic-03 {
        display: flex;
        margin: 0 auto;
        left: 0px;
        top: -50vh;
    }

    .infographic-line {
        display: flex;
        position: relative;
        visibility: visible;
        top: -13vw;
        margin: 0 auto;
        width: 75vw;
    }

    .infographic-06 {
        display: flex;
        position: static;
        padding-top: 27vh;
        margin: 0 auto;
        width: 75vw;
    }

    .waterq-mobile-aplication-container {
        display: flex;
        position: relative;
        top: 20vh;
        padding: 0% 24vw 0% 17vw;
        justify-content: space-between;
    }

    .analysis {
        top: -8vw;
    }

    .waterq-results-subtitle-two {
        width: 30vw;
        margin-right: 22vh;
        text-align: center;
    }

    .image-container-two {
        padding-top: -30vh;
    }

    .shutterstock-02 {
        height: 40vh;
    }

    .integration-resolution {
        width: 90vw;
        top: 30vh;
        left: 5vw;
        font-size: calc(1.2em + 1vmin);
        line-height: calc(1.3em + 1vmin);
    }

    .find-out-more {
        width: 40vw;
        top: 35vh;
        left: 30vw;
        border: 0.2vh solid #ffffff;
        border-radius: 5px;
        font-size: calc(0.5em + 1vmin);
        line-height: calc(2em + 1vmin);
    }
}
